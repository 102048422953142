@import 'fonts';

@import '../../node_modules/bootstrap/scss/functions';
@import '_variables';
// @import "../../node_modules/bootstrap/scss/variables-dark";
@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/utilities';

// Layout & components
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/containers';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/dropdown';
// @import "../../node_modules/bootstrap/scss/button-group";
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/navbar';
// @import "../../node_modules/bootstrap/scss/card";
@import '../../node_modules/bootstrap/scss/accordion';
@import '../../node_modules/bootstrap/scss/breadcrumb';
@import '../../node_modules/bootstrap/scss/pagination';
// @import "../../node_modules/bootstrap/scss/badge";
// @import "../../node_modules/bootstrap/scss/alert";
// @import "../../node_modules/bootstrap/scss/progress";
// @import "../../node_modules/bootstrap/scss/list-group";
@import '../../node_modules/bootstrap/scss/close';
@import '../../node_modules/bootstrap/scss/toasts';
@import '../../node_modules/bootstrap/scss/modal';
// @import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
// @import "../../node_modules/bootstrap/scss/spinners";
@import '../../node_modules/bootstrap/scss/offcanvas';
// @import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import '../../node_modules/bootstrap/scss/helpers';

// Utilities
@import '../../node_modules/bootstrap/scss/utilities/api';

// ХАК ДЛЯ ТОГО, ЧТОБ ОТКЛЮЧИТЬ :FOCUS ПО КЛИКУ, НО ПОКАЗЫВАТЬ ПРИ ТАБЕ С КЛАВИАТУРЫ

body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
	outline: none !important;
	appearance: none;
	box-shadow: none !important;
}

// КНОПКА ВВЕРХ

.scroll-to-top {
	bottom: 1rem;
	right: 1rem;
	width: 3.5rem;
	height: 3.5rem;
	padding: 0;
	z-index: 1;
	background-color: $primary;
	border-radius: $border-radius-lg;
	visibility: hidden;

	svg {
		width: 50%;
		height: auto;

		path {
			fill: $white;
		}
	}
}

// ТИПОГРАФИКА И ШИРОКИЕ ЭЛЕМЕНТЫ ДЛЯ ТЕКСТОВЫХ СТРАНИЦ

.article {
	font-size: 16px;

	> * {
		// max-width: 720px;
		// margin-left: auto;
		// margin-right: auto;

		&:last-child {
			margin-bottom: 0 !important;
		}
	}

	h1 {
		margin-bottom: 1.5rem;
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 1rem;
	}

	p,
	ul,
	ol {
		margin-bottom: 1.5rem;
		line-height: 1.55;
	}

	ul,
	ol {
		li {
			margin-bottom: 0.5rem;
		}

		ul,
		ol {
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
		}
	}

	.media {
		margin-bottom: 1.5rem;
	}

	.caption {
		max-width: 720px;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 0;
		padding-top: 0.25rem;
		font-size: 0.875rem;
	}

	.w-100 {
		max-width: 100% !important;

		> picture {
			max-width: 100%;

			img {
				max-width: 100%;
			}
		}
	}

	&-sm {
		font-size: 14px;

		h3 {
			@include font-size(22px);
		}

		p,
		ul,
		ol {
			margin-bottom: 1.25rem;
			line-height: 1.5;
		}

		ul,
		ol {
			padding-left: 1.25rem;
		}
	}

	&-md {
		font-size: 16px;

		> * {
			max-width: 100%;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&-main {
		h2 {
			@include font-size(2.5rem);
			font-weight: 900;
			margin-bottom: 1.5rem;
		}
	}
}

table {
	margin-bottom: 2rem !important;
	@include font-size(0.875rem);
}

ul {
	li {
		&::marker {
			color: $primary;
		}
	}
}

.img {
	position: relative;
	overflow: hidden;
	user-select: none;

	&-image {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 50%;
		transition: transform 0.1s ease-in-out;
	}

	&-zoom {
		&:hover {
			.img {
				&-image {
					transform: scale(1.03);
				}
			}
		}
	}
}

a[href^='tel:'],
a[href^='mailto:'] {
	white-space: nowrap;
}

.gallery a {
	&:hover {
		cursor: zoom-in;
	}
}

.blockquote {
	&-person {
		.img {
			height: 48px;
			width: 48px;
		}

		&-name {
			font-size: 15px;
			line-height: 1.35;
		}

		&-description {
			font-size: 14px;
		}

		&-main {
			img {
				height: auto;
				width: 336px;
			}

			.blockquote {
				&-icon {
					svg {
						width: 44px;
						height: 44px;

						path {
							fill: $primary;
						}
					}
				}
			}
		}
	}

	&-main {
		font-size: 1rem;
	}

	&-icon {
		svg {
			width: 44px;
			height: 44px;

			path {
				fill: $blue-100;
			}
		}
	}

	p {
		margin-bottom: 0.75rem;
	}

	&-footer {
		font-size: 1rem;
	}
}

.accordion {
	&-header {
		margin-bottom: 0 !important;
	}

	&-button {
		@include font-size(20px);

		span {
			margin-right: 1.5rem;
		}

		&::after {
			transition: all 0.15s ease-in-out;
		}

		&:not(.collapsed) {
			box-shadow: none !important;

			&::after {
				background-image: var(--bs-accordion-btn-icon);
			}
		}

		&:hover {
			color: $link-color;

			&::after {
				background-image: var(--bs-accordion-btn-active-icon);
			}
		}
	}

	&-body {
		font-size: 1rem;

		> * {
			&:last-child {
				margin-bottom: 0 !important;
			}
		}

		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 0.5rem;
		}

		h3 {
			@include font-size(22px);
		}

		h4 {
			@include font-size(20px);
		}

		h5 {
			@include font-size(18px);
		}

		h6 {
			@include font-size(16px);
		}

		p,
		ul,
		ol {
			margin-bottom: 1rem;
		}

		ul,
		ol {
			li {
				margin-bottom: 6px;
			}

			ul,
			ol {
				margin-top: 6px;
				margin-bottom: 6px;
			}
		}
	}
}

.documents,
.card-links,
.accordion,
.blockquote-container {
	margin-bottom: 2rem;
}

.document {
	font-size: 1rem;
	margin-bottom: 1rem;

	&:last-child {
		margin-bottom: 0;
	}

	&-body {
		&:hover {
			.icon {
				svg {
					path {
						fill: $primary;
					}
				}
			}
		}
	}

	.icon {
		svg {
			height: 20px;
			width: 20px;
		}
	}

	&-download {
		&:hover {
			.icon {
				svg {
					path {
						fill: $primary;
					}
				}
			}
		}
	}
}

.was-validated .form-check-input:valid:checked {
	background-color: $primary;
}

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
	color: $body-color;
}

.anchor {
	display: block;
	position: relative;
	top: -3.5rem;
	visibility: hidden;
}

.bg-primary-light {
	background-color: tint-color($primary, 95%);
}

.link-dark {
	color: $primary-text-emphasis !important;

	&:hover {
		color: $link-color !important;
	}

	&.link-icon,
	&.link-more {
		.icon {
			svg {
				path {
					fill: $dark;
				}
			}
		}

		&:hover {
			.icon {
				svg {
					path {
						fill: $link-color;
					}
				}
			}
		}
	}
}

.fw-black {
	font-weight: 900;
}

.btn-shadow {
	&.btn-primary {
		box-shadow: 0 1rem 1rem -1rem rgba($primary, 0.75);
	}
}

.btn-icon {
	.icon {
		svg {
			width: 20px;
			height: 20px;

			path {
				fill: $white;
			}
		}
	}
}

.link-icon {
	.icon {
		line-height: 0;

		svg {
			width: 16px;
			height: 16px;
		}
	}
}

.trans {
	transition-duration: 0.15s;
	transition-timing-function: ease-in-out;

	&-color {
		transition-property: color;
	}
}

.spaced-link {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: -8px;
		right: -8px;
		bottom: -8px;
		left: -8px;
	}
}

.link-more {
	.icon {
		svg {
			height: 9px;
			width: 9px;

			path {
				fill: $link-color;
			}
		}
	}

	&:hover {
		.icon {
			svg {
				path {
					fill: $link-hover-color;
				}
			}
		}
	}
}

.link-grid {
	&-description {
		font-size: 14px;
		line-height: 1.35 !important;
		margin-bottom: 0 !important;
	}
}

.link-list {
	margin-bottom: 1rem;

	&:last-child {
		margin-bottom: 0;
	}

	.icon {
		svg {
			height: 14px;
			width: 14px;
		}
	}

	&:hover {
		.icon {
			svg {
				path {
					fill: $link-color;
				}
			}
		}
	}
}

.caret,
.icon {
	flex-shrink: 0;
	line-height: 1.35;

	svg {
		path {
			transition: fill 0.15s ease-in-out;
		}
	}
}

.sidebar {
	.nav {
		flex-direction: column;
	}
	.nav-item {
		max-width: 100%;
	}

	.nav-link {
		color: $body-color;
		display: flex;
		align-items: center;
		padding: 0.5rem 0;

		&:hover,
		&.active {
			color: $link-color;

			svg {
				path {
					fill: $link-color;
				}
			}
		}

		.caret {
			padding-left: 1rem;
			margin-left: auto;

			svg {
				width: 8px;
				height: 8px;
			}
		}
	}

	.submenu {
		list-style: none;
		margin: 0;
		padding: 0;

		.nav-link {
			padding: 0.375rem 0 0.375rem 0.5rem;
			font-size: 14px;

			&.active,
			&:hover {
				color: $primary;
			}
		}
	}
}

.dropdown {
	&-toggle {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;

		.caret {
			svg {
				height: 8px;
				width: 8px;
			}
		}

		&:hover,
		&:focus {
			.caret {
				svg {
					path {
						fill: $primary;
					}
				}
			}
		}
	}

	&-menu {
		box-shadow: $box-shadow-lg;

		&.show {
			animation-name: FadeIn;
			animation-duration: 0.1s;
			animation-timing-function: ease-out;
		}
	}

	&-item {
		font-size: 14px;
		transition: color 0.15s ease-in-out;
	}
}

@keyframes FadeIn {
	0% {
		margin-top: -1rem;
		opacity: 0;
	}

	50% {
		opacity: 0.25;
	}

	100% {
		opacity: 1;
	}
}

.swiper {
	&-button-lock {
		display: none !important;
	}

	&-button-prev,
	&-button-next {
		width: 32px;
		height: 32px;
		background-color: $primary-bg-subtle;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 12px;
			height: 12px;

			path {
				fill: $primary-text-emphasis;
				transition: fill 0.15s ease-in-out;
			}
		}

		&:hover {
			background-color: $primary;
			cursor: pointer;
			transition: background-color 0.15s ease-in-out;

			svg {
				path {
					fill: $white;
				}
			}
		}

		&.swiper-button-disabled {
			opacity: 0.35;
			cursor: auto;
			pointer-events: none;
		}
		&.swiper-button-hidden {
			opacity: 0;
			cursor: auto;
			pointer-events: none;
		}
	}

	&-pagination-bullet {
		display: inline-block;
		width: 10px;
		height: 10px;
		background-color: $blue-100;
		border-radius: 50%;
		margin-right: 6px;

		&-active {
			background-color: $blue-200;
		}

		&:hover {
			cursor: pointer;
		}
	}

	.sale-banner {
		margin-bottom: 0;
	}

	&-slide {
		width: 0px;
	}
}

.logo {
	&-topbar {
		height: 44px;
		width: auto;
		user-select: none;
	}

	&-navbar {
		display: none;
		user-select: none;

		img {
			height: 30px;
			width: auto;
		}
	}

	&-footer {
		height: 40px;
		width: auto;
		margin-bottom: 4px;
		user-select: none;
	}
}

.fixed-top {
	.logo-navbar {
		display: inline-block;
	}

	.dropdown-phones {
		display: block;
	}
}

.dropdown-phones {
	display: none;
}

.navbar-nav {
	.nav-item {
		&:first-child {
			.nav-link {
				padding-left: 0;
			}
		}

		.nav-link {
			font-weight: 500;
		}
	}
}

.header,
.navbar {
	background-color: $blue-100;
}

.hero {
	background-image: linear-gradient(180deg, $blue-100 0, $body-bg 100%);

	&-title {
		@include font-size(3rem);
	}

	&-text {
		@include font-size(18px);
	}

	&-feature {
		z-index: 2;

		&-img {
			height: 36px;
			width: 36px;
			text-align: center;

			img {
				height: 36px;
				max-width: 100%;
			}
		}
	}
}

.bg-striped {
	position: relative;

	&::before,
	&::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		z-index: -1;
	}

	& > div {
		z-index: 1;
	}
}

.bg-wb {
	&::before {
		top: 0;
		bottom: 50%;
		background-color: $white;
	}

	&::after {
		top: 50%;
		bottom: 0;
		background-color: $body-bg;
	}
}

.doctor-card {
	&-title {
		font-size: 18px;
	}

	&-details {
		font-size: 14px;
	}

	&-achieve {
		font-size: 14px;

		.icon {
			svg {
				width: 12px;
				height: 12px;

				path {
					fill: $primary;
				}
			}
		}
	}
}

.service-card {
	&-img {
		width: 40px;
		height: 40px;
	}

	&-prices {
		background-color: $primary;

		&:hover {
			background-color: #0b5ed7;
		}
	}
}

.services-slider {
	.swiper-slide {
		height: auto;
	}
}

.price-list {
	padding: 0;
	margin: 0 0 1.75rem 0;

	&:last-child {
		margin-bottom: 0;
	}

	li {
		display: table;
		padding: 8px 0;

		> span {
			display: table-cell;

			&:first-child {
				position: relative;
				overflow: hidden;

				&:after {
					content: '';
					position: absolute;
					bottom: 0.55em;
					margin-left: 0.5em;
					width: 100%;
					border-bottom: 1px dashed $gray-400;
				}
			}

			& + span {
				white-space: nowrap;
				text-align: right;
				width: 1%;
				vertical-align: bottom;
				padding-left: 0.5em;
			}
		}
	}
}

.hardware-section-img {
	position: absolute;
	bottom: -5rem;
	left: 0;
	width: 250px;
	height: auto;
}

.about-stats-section-img {
	position: absolute;
	top: -300px;
	right: 106%;
	user-select: none;

	img {
		width: 300px;
		height: auto;
	}
}

.feature-hardware {
	&-text {
		span {
			display: block;
			font-size: 20px;
			font-weight: 700;
			color: $primary;
		}
	}

	&-img {
		width: 128px;
		position: relative;

		img {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
		}
	}
}

.feature-about {
	&-title {
		@include font-size(2.5rem);
		line-height: 1;
	}

	&-text {
		font-size: 15px;
	}
}

.section-title {
	@include font-size(2.5rem);
	font-weight: 900;
}

.linebar {
	.nav-link {
		padding: 0.5rem 0 0.5rem 0;
		margin-right: 2rem;
		position: relative;
		font-weight: 600;

		&.active {
			color: $body-color;
		}
	}
}

.address {
	&-map {
		height: 500px;
	}

	&-header {
		@include font-size(20px);
		line-height: 1.35;
	}

	&-title {
		font-size: 14px;
		margin-bottom: 2px;
	}

	&-value {
		margin-bottom: 2px;
	}

	&-item {
		margin-bottom: 1rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&-img {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgb(255, 255, 255);
			background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba($primary, 0.15) 100%);
		}

		.img-image {
			object-position: 100% 50%;
			filter: grayscale(1);
			opacity: 0.3;
		}
	}
}

.single-slider {
	.swiper {
		border-radius: $border-radius-lg;
	}
}

.call-us-img {
	position: absolute;
	bottom: -3rem;
	right: 0;
	width: 100%;

	img {
		width: 100%;
		height: auto;
	}
}

.footer {
	&-text {
		font-size: 14px;
		margin-bottom: 0.75rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&-link {
		background-color: tint-color($primary, 95%);
		transition: all 0.15s ease-in-out;

		&-icon {
			width: 24px;
			height: 24px;
			background-color: $blue-200;
			transition: background-color 0.15s ease-in-out;

			img {
				width: 16px;
				height: 16px;
			}
		}

		&:hover {
			background-color: $white;
			box-shadow: 0 0 1rem rgba($black, 0.05);

			.footer-link-icon {
				background-color: $blue-300;
			}
		}
	}
}

.form-check-sm {
	.form-check-label {
		font-size: 14px;
		line-height: 1.3;
		margin-top: 2px;
	}
}

.modal-success-img {
	width: 100%;
	position: relative;
	right: -2rem;
	bottom: -2rem;
}

.modal-header {
	p {
		font-size: 15px;
	}
}

.modal-title {
	font-size: 22px;
}

.modal-content {
	overflow: hidden;
}

.appointment-img {
	height: 100%;
}

@include media-breakpoint-down(lg) {
	table {
		& > :not(caption) > * > * {
			padding: 6px !important;
		}
	}

	.breadcrumb {
		font-size: 0.75rem;
	}

	.swiper {
		overflow: visible;
	}
}

@include media-breakpoint-down(md) {
	.article {
		font-size: 16px;

		h1 {
			margin-bottom: 1.25rem;
		}

		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 0.75rem;
		}

		h2 {
			font-size: 20px;
		}

		h3 {
			font-size: 19px;
		}

		h4 {
			font-size: 18px;
		}

		h5 {
			font-size: 17px;
		}

		h6 {
			font-size: 16px;
		}

		p,
		ul,
		ol {
			margin-bottom: 1.25rem;
			line-height: 1.5;
		}

		.caption {
			font-size: 12px;
		}
	}

	table {
		margin-bottom: 1.25rem !important;
		@include font-size(0.75rem);

		& > :not(caption) > * > * {
			padding: 0.25rem !important;
		}

		caption {
			font-size: 12px !important;
		}
	}

	.documents,
	.accordion,
	.card-links,
	.blockquote-container {
		margin-bottom: 1.5rem;
	}

	.document {
		font-size: 14px;
		margin-bottom: 0.5rem;
	}

	.link-list {
		margin-bottom: 0.5rem;
	}

	.accordion {
		&-button {
			@include font-size(18px);
		}
	}

	.blockquote {
		font-size: 18px;

		&-person {
			.img {
				height: 36px;
				width: 36px;
			}

			&-name {
				font-size: 13px;
				margin-bottom: 2px;
			}

			&-description {
				font-size: 12px;
			}
		}

		&-icon {
			svg {
				width: 36px;
				height: 36px;
			}
		}

		p {
			margin-bottom: 0.5rem;
		}

		&-footer {
			font-size: 14px;
		}
	}
}

@include media-breakpoint-down(sm) {
	.breadcrumb {
		font-size: 0.625rem;
	}

	table {
		margin-bottom: 1.5rem;
		@include font-size(0.75rem);

		& > :not(caption) > * > * {
			padding: 2px !important;
		}
	}

	.scroll-to-top {
		display: none !important;
	}
}

.anim {
	animation-duration: 0.3s;
	animation-timing-function: ease-in-out;
	animation-delay: 0;
	animation-iteration-count: 1;
	animation-direction: alternate;
	animation-fill-mode: both;
	animation-play-state: paused;

	&.play {
		animation-play-state: running;
	}
}

.slide-up {
	animation-name: SlideUp;
}

.slide-left {
	animation-name: SlideLeft;
}

.slide-right {
	animation-name: SlideRight;
}

@keyframes SlideUp {
	0% {
		transform: translateY(2rem);
		opacity: 0;
	}

	100% {
		transform: translateY(0rem);
		opacity: 1;
	}
}

@keyframes SlideLeft {
	0% {
		transform: translateX(2rem);
		opacity: 0;
	}

	100% {
		transform: translateX(0rem);
		opacity: 1;
	}
}

@keyframes SlideRight {
	0% {
		transform: translateX(-2rem);
		opacity: 0;
	}

	100% {
		transform: translateX(0rem);
		opacity: 1;
	}
}
